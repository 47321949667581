<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">{{ translate('general') }}</li>
      <hr class="bg-secondary divider-line">

      <li>
        <router-link class="side-nav-link-ref" to="/dashboard">
          <icon type="home"/>
          <span>{{ translate('home') }}</span>
        </router-link>
      </li>

      <!-- CUSTOMERS-->
      <li class="">
        <router-link :to="{name: 'customers'}" v-if="hasFeaturePermissionForType('CUSTOMERS')"
           class="side-nav-link-ref">
          <icon type="customer"/>
          <span>{{ translate('customers') }}</span>
        </router-link>
      </li>

      <!-- PROPERTY-->
      <li class="">
        <router-link :to="{name: 'property'}" v-if="hasFeaturePermissionForType('PROPERTY_ASSETS')"
                     class="side-nav-link-ref">
          <icon type="property"/>
          <span>{{ translate('property') }}</span>
        </router-link>
      </li>

      <!-- DASHBOARDS-->
      <li class="">
        <router-link :to="{name: 'dashboards'}" v-if="hasFeaturePermissionForType('STB_DASHBOARDS') || hasFeaturePermissionForType('MOBILE_DASHBOARDS')"
                     class="side-nav-link-ref">
          <icon type="dashboard"/>
          <span>{{ translate('dashboards') }}</span>
        </router-link>
      </li>

      <!-- PACKAGES -->
      <li v-if="hasFeaturePermissionForType('PACKAGES')">
        <router-link :to="{name: 'packages', params:{folderId: 1}}" class="side-nav-link-ref">
          <icon type="packages"/>
          <span>
                {{ translate('packages') }}
              </span>
        </router-link>
      </li>

      <!-- MEDIA LIBRARY-->
      <li v-if="hasFeaturePermissionForType('MEDIA_LIBRARY')">
        <router-link :to="{name: 'media.folder', params:{folderId: 0}}" class="side-nav-link-ref">
          <icon type="mediaLibrary"/>
          <span>
            {{ translate('media-library') }}
          </span>
        </router-link>
      </li>


      <!-- SECTION: MODULES -->
      <li class="menu-title">
        {{ translate('modules') }}
      </li>
      <hr class="bg-secondary divider-line">

      <!-- CATALOGUES -->
      <li v-if="hasModulePermissionForType('CATALOGUE')">
        <a :class="{'has-arrow': hasModulesOfType('CATALOGUE')}" aria-expanded="false"
           class="is-parent has-add">
          <icon type="catalogues"/>
          <span>{{ translate('catalogues') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('CATALOGUE')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('CATALOGUE')">
            <li>
              <router-link :to="{name: 'catalogue.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>
      <!-- TV CHANNELS -->
      <li v-if="hasModulePermissionForType('TV_RADIO')">
        <a :class="{'has-arrow': hasModulesOfType('TV_RADIO')}" aria-expanded="false"
           class="is-parent has-add">
          <icon type="tv"/>
          <span>{{ translate('tv-channels') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('TV_RADIO')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('TV_RADIO')">
            <li>
              <router-link :to="{name: 'tv.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- LIVE CAMERAS -->
      <li style="display: none" v-if="true || hasModulePermissionForType('LIVE_CAMERAS')">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="liveCameras"/>
          <span>{{ translate('live-cameras') }}</span>
        </router-link>
      </li>

      <!-- VOD -->
      <li v-if="hasModulePermissionForType('VOD')">
        <a :class="{'has-arrow': hasModulesOfType('MOD') || hasModulesOfType('SERIES')}" aria-expanded="false"
           class="is-parent has-add">
          <icon type="vod"/>
          <span>{{ translate('vod') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('VOD')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('MOD')">
            <li>
              <router-link :to="{name: 'mod.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('SERIES')">
            <li>
              <router-link :to="{name: 'series.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- VIDEO -->
      <li v-if="hasModulePermissionForType('VIDEO')">
        <a :class="{'has-arrow': hasModulesOfType('VIDEO')}" aria-expanded="false"
           class="is-parent has-add">
          <icon type="video"/>
          <span>{{ translate('video') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('VIDEO')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('VIDEO')">
            <li>
              <router-link class="side-nav-link-ref" :to="{name: 'video.index', params: {moduleId: module.id}}">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- AOD -->
      <li v-if="hasModulePermissionForType('AOD')">
        <a :class="{'has-arrow': hasModulesOfType('AOD')}" aria-expanded="false"
           class="is-parent has-add">
          <icon type="aod"/>
          <span>{{ translate('aod') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('AOD')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('AOD')">
            <li>
              <router-link class="side-nav-link-ref" :to="{name: 'aod.index', params: {moduleId: module.id}}">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- ESSENTIALS -->
      <li>
        <a aria-expanded="false" class="is-parent has-arrow">
          <icon type="essentials"/>
          <span>{{ translate('essentials') }}</span>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <li v-if="hasPermissionForGuestFlowType('BASIC_PROFILING')">
            <router-link :to="{name: 'interests.index', params: {id: 0}}"
                         class="side-nav-link-ref">
              <span>{{ translate('my-interests') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('ACCOMODATION_LIBRARY')">
            <router-link class="side-nav-link-ref" :to="{name: 'itineraries.index'}">
              <span>{{ translate('itineraries') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('RATINGS')">
            <router-link class="side-nav-link-ref" :to="{name: 'ratings.index'}">
              <span>{{ translate('ratings') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('SOCIAL_MEDIA')">
            <router-link class="side-nav-link-ref" to="/social-media">
              <span>{{ translate('social-media') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('SAVE_PLANET')">
            <router-link class="side-nav-link-ref" :to="{name: 'skipCleaning'}">
              <span>{{ translate('skip-cleaning') }}</span>
            </router-link>
          </li>
          <li v-if="hasPermissionForGuestFlowType('PROMOTIONS')">
            <router-link class="side-nav-link-ref" to="/promotions">
              <span>{{ translate('promotions') }}</span>
            </router-link>
          </li>
          <li v-if="hasModulePermissionForType('CONTACTS')">
            <router-link class="side-nav-link-ref" to="/contact/module">
              <span>{{ translate('contacts') }}</span>
            </router-link>
          </li>
          <li style="display: none">
            <router-link class="side-nav-link-ref" to="/404">
              <span>{{ translate('prayer-time') }}</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- ROOM CARE -->
      <li v-if="hasModulePermissionForType('SERVICE') && roomCareModule">
        <router-link class="side-nav-link-ref" :to="{name: 'service.index', params: {moduleId: roomCareModule.id}}">
          <icon type="roomCare"/>
          <span>{{ translate('room-care') }}</span>
        </router-link>
      </li>

      <!-- FAST CHECK IN -->
      <li v-if="hasPermissionForGuestFlowType('FAST_CHECK_IN')">
        <router-link class="side-nav-link-ref" :to="{name: 'fastCheckIn'}">
          <icon type="fastCheckIn"/>
          <span>{{ translate('fast-check-in') }}</span>
        </router-link>
      </li>

      <!-- MY MOOD -->
      <li v-if="hasPermissionForGuestFlowType('EMOTION_RECOGNITION')">
        <router-link class="side-nav-link-ref" :to="{name: 'myMood'}">
          <icon type="myMood"/>
          <span>{{ translate('my-mood') }}</span>
        </router-link>
      </li>

      <!-- SURVEY -->
      <li style="display: none">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="survey"/>
          <span>{{ translate('survey') }}</span>
        </router-link>
      </li>

      <!-- APPS-->
      <li v-if="hasModulePermissionForType('APPLICATION')">
        <a
          :class="{'has-arrow': hasModulesOfType('APPLICATION')
          || hasModulesOfType('WEBSITE_APPLICATION')
          || hasModulesOfType('TV_APPLICATION')
        }" aria-expanded="false"
           class="is-parent has-add">
          <icon type="apps"/>
          <span>{{ translate('apps') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('APPLICATION')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('APPLICATION')">
            <li>
              <router-link :to="{name: 'applications.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('TV_APPLICATION')">
            <li>
              <router-link :to="{name: 'applications.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('WEBSITE_APPLICATION')">
            <li>
              <router-link :to="{name: 'applications.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- WIDGETS -->
      <li v-if="hasModulePermissionForType('TIME') || hasModulePermissionForType('WEATHER')">
        <a :class="{'has-arrow': hasModulesOfType('TIME') || hasModulesOfType('WEATHER')}"
           aria-expanded="false"
           class="is-parent has-add">
          <icon type="widgets"/>
          <span>{{ translate('widgets') }}</span>
          <icon type="add" @click.stop="openCreateModulePopup('WIDGETS')"/>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <template v-for="module in getModulesOfType('TIME')">
            <li>
              <router-link :to="{name: 'time.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
          <template v-for="module in getModulesOfType('WEATHER')">
            <li>
              <router-link :to="{name: 'weather.index', params: {moduleId: module.id}}"
                           class="side-nav-link-ref">
                {{ translate(module.name) }}
              </router-link>
            </li>
          </template>
        </ul>
      </li>

      <!-- NOTIFICATIONS -->
      <li v-if="hasFeaturePermissionForType('NOTIFICATIONS')">
        <router-link class="side-nav-link-ref" :to="{name: 'notifications'}">
          <icon type="notifications"/>
          <span>{{ translate('notifications') }}</span>
        </router-link>
      </li>

      <!-- SHIP POSITION -->
      <li v-if="hasModulePermissionForType('SHIP_POSITION_APPLICATION') && shipPositionModule">
        <router-link class="side-nav-link-ref" :to="{name: 'applications.index', params: {moduleId: shipPositionModule.id}}">
          <icon type="shipPosition"/>
          <span>{{ translate('ship-position') }}</span>
        </router-link>
      </li>

      <!-- CASTING -->
      <li style="display: none">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="cast"/>
          <span>{{ translate('casting') }}</span>
        </router-link>
      </li>

      <!-- DIGITAL SIGNAGE -->
      <li style="display: none">
        <router-link class="side-nav-link-ref" to="/404">
          <icon type="digitalSignage"/>
          <span>{{ translate('digital-signage') }}</span>
        </router-link>
      </li>


      <!-- SECTION: SYSTEM SETTINGS -->
      <template v-if="showSystemSettings">
        <li class="menu-title">
          {{ translate('system-settings') }}
        </li>
        <hr class="bg-secondary divider-line">
      </template>

      <li v-if="hasFeaturePermissionForType('SETTINGS')" class="">
        <a aria-expanded="false" class="is-parent has-arrow">
          <icon type="settings"/>
          <span>
            {{ translate('settings') }}
          </span>
        </a>
        <ul aria-expanded="false" class="sub-menu mm-collapse" style="">
          <li>
            <router-link :to="{name: 'general.setting'}" class="side-nav-link-ref">
              {{ translate('general-configuration') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'app.setting'}" class="side-nav-link-ref">
              {{ translate('app-configuration') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'analytic.setting'}" class="side-nav-link-ref">
              {{ translate('analytic-configuration') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'user.settings'}" class="side-nav-link-ref">
              {{ translate('user-settings') }}
            </router-link>
          </li>

          <!-- LOCALIZATION -->
          <li>
            <router-link aria-expanded="false" :to="{name: 'localization', params:{folderId: 1}}" class="side-nav-link-ref">
            <i class="fas fa-language pr-2"></i>
              <span>
                {{ translate('localization') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'system.services'}" class="side-nav-link-ref">
              <span>
                {{ translate('system-services') }}
              </span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- ACCESS & ROLES-->
      <li v-if="hasFeaturePermissionForType('ADMINS')" class="">
        <router-link :to="{name: 'access.roles', params:{folderId: 1}}" class="side-nav-link-ref">
          <icon type="property"/>
          <span>{{ translate('access-&-roles') }}</span>
        </router-link>
      </li>
    </ul>


    <CreateModule ref="createModule"
                  :moduleType="createModuleType"/>
  </div>
</template>


<script>
import MetisMenu from 'metismenujs/dist/metismenujs';
import stores from '@/stores';
import axiosInstance from '@/helpers/axios';

import CreateModule from '@/modules/Modules/Create.vue';
import {
  hasPermission,
  hasFeaturePermissionForType,
  hasModulePermissionForType,
  hasPermissionForGuestFlowType
} from '@/helpers/permissions';

export default {
  components: {
    CreateModule
  },
  data() {
    return {
      menuRef: null,
      modules: [],
      menus: [],
      staticMenus: [],
      counter: 0,
      admin: stores.admin,
      timer: '',
      activeLink: null,

      createModuleType: null,
    };
  },
  watch: {
    $route(to, from) {
      this.menuManagement();
    },
  },
  computed: {
    showSystemSettings() {
      return this.hasFeaturePermissionForType('SETTINGS') || this.hasFeaturePermissionForType('ADMINS');
    },
    roomCareModule(){
      return this.getModulesOfType('SERVICE')[0];
    },
    shipPositionModule(){
      return this.getModulesOfType('SHIP_POSITION_APPLICATION')[0];
    }
  },
  mounted() {
    this.$bus.$on('active', () => {
      return axiosInstance.get(`/modules`)
        .then((response) => {
          this.menus = response.data;
          this.menuManagement();
          return response.data;
        })
        .catch((error) => error);
    });
    this.menus = stores.modules.models;
    this.menuRef = new MetisMenu("#side-menu"); // IMPORTANT: Side menu initialization
    this.menuManagement();
    stores.GuestFlow.listofServices()
      .then((response) => {
        this.staticMenus = response.data;
      });
  },
  methods: {

    hasFeaturePermissionForType,
    hasPermissionForGuestFlowType,
    hasModulePermissionForType,
    hasPermission,

    openCreateModulePopup(moduleType) {
      this.createModuleType = moduleType;
      this.$refs.createModule.$children[0].open();
    },

    getModulesOfType(moduleType) {
      return this.menus.filter(menu => (menu.type === moduleType));
    },
    hasModulesOfType(moduleType) {
      return this.getModulesOfType(moduleType).length > 0;
    },
    // menuManagement() updates the styling of the side menu based on the current page
    async menuManagement() {
      await stores.modules.fetchPromise;
      this.$nextTick(() => {
        const previousActiveLink = this.activeLink;
        const links = this.$el.querySelectorAll(".side-nav-link-ref");
        links.forEach((link) => {
          if (link.pathname === window.location.pathname) {
            this.activeLink = link;
          }
        });

        if (!this.activeLink) return;
        if (this.activeLink !== previousActiveLink) this.collapseMenus();
        this.resetMenuStyles();

        this.activeLink.classList.add("active");
        let parent = this.activeLink.parentElement;
        while (parent && parent.id !== "side-menu") {
          if (parent.matches("li")) {
            parent.classList.add("mm-active");
          }
          if (parent.matches("ul.sub-menu")) {
            parent.classList.add("mm-show");
            const childAnchor = parent.parentElement.querySelector(".has-arrow");
            const childDropdown = parent.parentElement.querySelector(".has-dropdown");

            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
          }
          parent = parent.parentElement;
        }
      });
    },
    resetMenuStyles() {
      const links = this.$el.querySelectorAll(".side-nav-link-ref");
      links.forEach((link) => {
        link.classList.remove("active");
      });

      const activeLinks = this.$el.querySelectorAll(".mm-active");
      activeLinks.forEach((link) => {
        link.classList.remove("mm-active");
      });
    },
    collapseMenus() {
      const showLinks = this.$el.querySelectorAll(".mm-show");
      showLinks.forEach((link) => {
        link.classList.remove("mm-show");
      });
    }


  },
};
</script>

<style scoped>
.nevron-icon {
  margin-right: 10px;
}

.nevron-icon.svg {
  width: 25px;
  height: 25px;
}

.is-parent {
  cursor: pointer;
}

.has-add {
  display: flex !important;
  align-items: center !important;
}

.has-add .nevron-icon.add {
  margin: 0 0 0 auto;
  padding: 5px;
}

.menu-title {
  padding-bottom: 0px !important;
}

.divider-line {
  margin: 5px 0;
}
</style>
