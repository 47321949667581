<template>
  <span v-if="!isSvgIcon"
        :class="iconClass"
        :data-placement="hasTooltip ? 'bottom' : null"
        :data-toggle="hasTooltip ? 'tooltip' : null"
        :title="hasTooltip ? (title ? title : icons[type].tooltip) : null"
        @click="$emit('click', $event)">
  </span>
  <img v-else
       :class="iconClass"
       :data-placement="hasTooltip ? 'bottom' : null"
       :data-toggle="hasTooltip ? 'tooltip' : null"
       :src="icons[type].path"
       :title="hasTooltip ? (title ? title : icons[type].tooltip) : null"
       @click="$emit('click', $event)"/>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    hasTooltip: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    iconClass() {
      if (!this.icon) {
        return {};
      }
      return {
        'nevron-icon': true,
        'svg': this.isSvgIcon,
        [this.type]: true,
        [this.icon.code]: (this.icon && this.icon.code),
      };
    },
    icon() {
      return this.icons[this.type];
    },
    isSvgIcon() {
      return this.icon && this.icon.path;
    }
  },
  data() {
    return {
      icons: {
        remove: {code: 'fas fa-trash-alt', tooltip: 'Remove'},
        edit: {code: 'fas fa-edit', tooltip: 'Edit'},
        unlink: {code: 'fas fa-times text-danger', tooltip: 'Unlink'},
        import: {code: 'fas fa-download', tooltip: 'Import'},
        export: {code: 'fas fa-upload', tooltip: 'Export'},
        refresh: {code: 'fas fa-sync', tooltip: 'Refresh'},
        copy: {code: 'far fa-copy', tooltip: 'Copy'},
        copied: {code: 'fas fa-copy', tooltip: 'Copied'},
        add: {code: 'fas fa-plus', tooltip: 'Add'},
        right: {code: 'fas fa-arrow-right', tooltip: 'Right Arrow'},
        left: {code: 'fas fa-arrow-left', tooltip: 'Left Arrow'},
        back: {code: 'fas fa-chevron-left', tooltip: 'Back Arrow'},

        // SVG Icons
        accessRoles: {
          path: require('@/assets/icons/white/access_roles-w.svg'),
          tooltip: 'Access Roles'
        },
        aod: {path: require('@/assets/icons/white/aod-w.svg'), tooltip: 'AOD'},
        apps: {path: require('@/assets/icons/white/apps-w.svg'), tooltip: 'Apps'},
        cast: {path: require('@/assets/icons/white/cast-w.svg'), tooltip: 'Cast'},
        catalogues: {path: require('@/assets/icons/white/catalogues-w.svg'), tooltip: 'Catalogues'},
        customer: {path: require('@/assets/icons/white/customer-w.svg'), tooltip: 'Customers'},
        dashboard: {path: require('@/assets/icons/white/dashboards-w.svg'), tooltip: 'Dashboard'},
        digitalSignage: {
          path: require('@/assets/icons/white/digital_signage-w.svg'),
          tooltip: 'Digital Signage'
        },
        essentials: {path: require('@/assets/icons/white/essentials-w.svg'), tooltip: 'Essentials'},
        fastCheckIn: {
          path: require('@/assets/icons/white/fast_check_in-w.svg'),
          tooltip: 'Fast Check In'
        },
        home: {path: require('@/assets/icons/white/home-w.svg'), tooltip: 'Home'},
        liveCameras: {
          path: require('@/assets/icons/white/live_cameras-w.svg'),
          tooltip: 'Live Cameras'
        },
        location: {path: require('@/assets/icons/white/location-w.svg'), tooltip: 'Location'},
        mediaLibrary: {
          path: require('@/assets/icons/white/media_library-w.svg'),
          tooltip: 'Media Library'
        },
        myMood: {path: require('@/assets/icons/white/my_mood-w.svg'), tooltip: 'My Mood'},
        notifications: {
          path: require('@/assets/icons/white/notifications-w.svg'),
          tooltip: 'Notifications'
        },
        packages: {path: require('@/assets/icons/white/packages-w.svg'), tooltip: 'Packages'},
        property: {path: require('@/assets/icons/white/property-w.svg'), tooltip: 'Property'},
        roomCare: {path: require('@/assets/icons/white/room_care-w.svg'), tooltip: 'Room Care'},
        settings: {path: require('@/assets/icons/white/settings-w.svg'), tooltip: 'Settings'},
        survey: {path: require('@/assets/icons/white/survey-w.svg'), tooltip: 'Survey'},
        tv: {path: require('@/assets/icons/white/tv-w.svg'), tooltip: 'TV Channels'},
        vod: {path: require('@/assets/icons/white/vod-w.svg'), tooltip: 'VOD'},
        widgets: {path: require('@/assets/icons/white/widgets-w.svg'), tooltip: 'Widgets'},
        video: {path: require('@/assets/icons/white/sod-w.svg'), tooltip: 'SOD'},
        shipPosition: {path: require('@/assets/icons/white/ship_position-w.svg'), tooltip: 'Ship Position'},
      },
    };
  },
};
</script>
