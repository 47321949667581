
import {Component, Vue} from 'vue-property-decorator';
import NevronHeader from '@/components/NevronHeader.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import AddSection from '@/components/DashboardComposer/AddSection.vue';
import EServices from '@/components/DashboardComposer/EServices.vue';
import CollectionItem from '@/components/DashboardComposer/CollectionItemGuestFlow.vue';
import Categories from '@/components/DashboardComposer/Categories.vue';
import Items from '@/components/DashboardComposer/ItemsGuestFlow.vue';
import SingleItem from '@/components/DashboardComposer/AppendItems.vue';
import EditPanel from '@/components/DashboardComposer/EditPanel.vue';
import axiosInstance from '../../../../helpers/axios';
import stores from '@/stores';
import {DashboardPanel, Dashboards, PanelElement} from '@/stores/Dashboard';
import EditElement from '@/components/DashboardComposer/EditElement.vue';
// @ts-ignore
import Sortable from 'vue-drag-sortable';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronInput from '@/components/NevronInput.vue';
import GuestFlow from '@/components/DashboardComposer/guestFlow.vue';
import {createItemPanelElement, showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';
import DashboardSettings from '@/components/DashboardComposer/DashboardSettings.vue';

@Component({
  components: {
    EditPanel,
    SingleItem,
    NevronHeader,
    AttachImage,
    AddSection,
    EServices,
    CollectionItem,
    Categories,
    Items,
    Sortable,
    EditElement,
    DynamicContent,
    NevronInput,
    GuestFlow,
    Skeleton,
    DashboardSettings,
  },
})
export default class DashboardComposer extends Vue {
  dragData: any = {};
  // header background attributes
  selectedPanel: any = null;
  // container for composer dashboard component
  panels: any = [];

  dashboardSetting: any = {
    radius: '10',
    bgColor: '',
    neutralColor: '',
    primaryColor: '',
    logo: null,
    imageId: null,
    bgImageId: null,
    backGroundImage: null,
    gradiantStartColor: null,
    gradiantEndColor: null,
    imageUrl: '',
    isGradiant: '',
    buttonTextColor: null,
    selectionColor: null,
    alertColor: null,
    reminderColor: null,
    topGradiant: null,
    bottomGradiant: null,
    floatMenuColor: null,
    isImageOnDashboard: false,
  };
  dashboard: IDashboards = new Dashboards({name: 'Composer Dashboard', type: 'Mobile'});
  // one slider only for one dashboard therefor separate instant
  slider: any = null;
  sliderInterval: any = null;
  floatingSection: any = null;
  checkFloatingSection: any = null;
  checkGFModule: any = {
    elements: [],
  };
  // helper veriables
  selectedPanelIndex: any = 1;
  selectedElementIndex: any = 1;
  selectedElement: any = null;
  panel: any = {
    id: null,
    dashboardId: null,
    name: '',
    sort: null,
    elements: [],
  };
  localText: any = '';
  localKey: any = '';
  notification: any = {
    result: false,
    message: '',
  };
  notificationTimer: any;
  attachableContent: any = null;
  contentUrl: any = '';
  buttonWidth: any = 511;
  isGradiant: boolean = false;

  get createBackgroundHeaderColor() {
    return `linear-gradient(90deg, ${this.dashboardSetting.gradiantStartColor + 'cc'} 40%, ${this.dashboardSetting.gradiantEndColor + 'cc'}  60%)`;
  }

  mounted() {
    clearInterval(this.sliderInterval);

    this.selectedElementIndex = 0;
    this.loadComposerData(this.$route.params.id);
    // @ts-ignore
    this.$bus.$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
  }
  isImageFile(fileExtension: any) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif','eps','bmp','tiff','tif','svg','raw'];
    return imageExtensions.includes(fileExtension);
  };
  isVideoFile(fileExtension: any) {
    const videoExtensions = ['mp4', 'mov', 'avi','wmv','webm','mkv','mpeg-4','ogg'];
    return videoExtensions.includes(fileExtension);
  };
  videoType(type: any) {
    return 'video/' + type;
  };
  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.dashboardSetting.imageUrl = response.imageUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  attachWithImage(res: any) {
    this.dashboardSetting.imageUrl = res.result.imageUrl;
    this.dashboardSetting.bgImageId = res.result.id;
    // @ts-ignore
    this.$refs.image.$children[0].close();
    if (this.dashboardSetting.imageUrl === '' || this.dashboardSetting.imageUrl === null) {
      this.buttonWidth = `margin-top: ${511}px;`;
    } else {
      this.buttonWidth = `margin-top: ${860}px;`;
    }
    this.getImage(res.result.id);
  }

  handleTranslation(data: any) {
    this.localText = data.value;
    this.localKey = data.key;
    // @ts-ignore
    this.$refs.translator.$children[0].open();
  }

  // load dashboard data
  loadComposerData(id: any) {
    stores.mobileComposer.getDashboard(id)
      .then((response: any) => {

        // assigning response objects to local variables
        this.dashboard = response.dashboard;
        this.dashboardSetting = response.setting;
        this.floatingSection = response.floatingSection;
        this.slider = response.slider;
        const tempArray = response.panels;
        this.dashboardSetting.imageUrl = response.setting.imageUrl;

        if (this.dashboardSetting.imageUrl === '' || this.dashboardSetting.imageUrl === null) {
          this.buttonWidth = `margin-top: ${511}px;`;
        } else {
          this.buttonWidth = `margin-top: ${860}px;`;
        }

        this.panels = [];
        for (const index in tempArray) {
          if (index && tempArray[index]) {
            this.panels.push(tempArray[index]);
          }
        }

        if (this.slider) {
          this.slider.linkElementId = 0;
        }
        this.dashboardSetting.isGradiant = !(response.setting.isGradiant === '0' || response.setting.isGradiant === 0);
      });
    this.selectedElementIndex = 0;
  }

  save() {
    const data = JSON.parse(JSON.stringify(this.dashboard));
    data.setting = this.dashboardSetting;
    data.panels = this.panels;
    data.slider = this.slider;
    data.floating = this.floatingSection;
    delete data.public;
    stores.mobileComposer.updateItem(this.dashboard.id, data)
      .then((response) => {
        if (response.responseCode === 200) {
          this.loadComposerData(this.$route.params.id);
          showToaster('success', Vue.prototype.translate(this.dashboard.name), 'successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.dashboard.name), 'Fail to update');
        }
      }).catch((error) => {
      showToaster('danger', Vue.prototype.translate(this.dashboard.name), 'Fail to update');
    });
  }

  // not using anywhere but can be use in future
  // setNotification(result: any, message: any) {
  //   this.notification = {
  //     result,
  //     message,
  //   };
  //   this.notificationTimer = setTimeout(() => {
  //     this.notification = {
  //       result: false,
  //       message: '',
  //     };
  //   }, 5000);
  // }

  updateSliderElement(result: any) {
    this.slider.elements[result.elementIndex] = result.element;
    this.slider.showName = result.sliderShowName;
  }

  editSliderElement(index: any, element: any) {
    this.selectedElementIndex = index;
    this.selectedElement = element;
    // @ts-ignore
    this.$refs.editElement.$children[0].open();
  }

  // sorting functionality
  sort(e: any) {
    const {oldIndex, newIndex} = e;
    console.log(oldIndex, newIndex);
  }

  sorting(e: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(this.panels, oldIndex, newIndex);
  }

  sortend(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(list, oldIndex, newIndex);
  }

  rearrange(panelList: [], oldIndex: any, newIndex: any) {
    if (oldIndex > newIndex) {
      this.panels.splice(newIndex, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex + 1, 1);
    } else {
      this.panels.splice(newIndex + 1, 0, this.panels[oldIndex]);
      this.panels.splice(oldIndex, 1);
    }
  }

  showSectionOption() {
    // @ts-ignore
    this.$refs.section.$children[0].open();
  }

  // perform action on add saction model
  sectionStepOne(option: string) {
    // @ts-ignore
    this.$refs.section.$children[0].close();
    console.log('option > > ', option);
    if (option === 'banner') {

      this.slider = this.panel;
      this.slider.name = 'Slider';
      this.slider.panelType = 'slider';
      this.slider.linkElementId = 0;
      this.slider.elements = [];
    } else if (option === 'collection') {
      // @ts-ignore
      this.$refs.collections.$children[0].open();
    }
  }

  // handle type of collection
  collectionResponse(option: string) {
    // @ts-ignore
    this.$refs.collections.$children[0].close();

    if (option === 'services') {
      // @ts-ignore
      this.$refs.services.$children[0].open();
    } else if (option === 'categories') {
      // @ts-ignore
      this.$refs.categories.$children[0].open();
    } else if (option === 'guestflow') {
      // @ts-ignore
      this.$refs.guestflow.$children[0].open();
    } else if (option === 'items') {
      // @ts-ignore
      this.$refs.items.$children[0].open();
    }
  }

  // add services to dashboard
  servicesResponse(services: []) {
    console.log('services >>', services);
    if (services.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'eServices';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in services) {
        if (index && services[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: services[index].name,
            // @ts-ignore
            linkModuleId: services[index].id,
            linkLayout: 'element',
            // @ts-ignore
            image: services[index].image ? services[index].image.imageUrl : null,
            // @ts-ignore
            imageId: services[index].image ? services[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;

      this.panels.push(temp);
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
  }

  categoriesResponse(categories: []) {
    console.log('services >>', categories);
    if (categories.length > 0) {
      const temp = new DashboardPanel();
      temp.name = 'Categories';
      for (const index in categories) {
        if (index && categories[index]) {
          const tempService = new PanelElement();
          // @ts-ignore
          tempService.linkModuleId = services[index].id;

          temp.elements.push(tempService);
        }
      }
      this.panels.push(temp);
    }

    console.log('panels >>', this.panel);
    this.save();
  }

  // add collection of items
  itemsResponse(result: PanelElement) {
    this.panels.push(result);
    console.log('panel >>', result);
  }

  guestFlowResponse(guestFlow: []) {
    console.log('guestFlow >>', guestFlow);
    if (guestFlow.length > 0) {
      const temp = new DashboardPanel({active: 1});
      temp.name = 'guestFlow';
      // @ts-ignore
      temp.elementType = this.$refs.services.data.ratio;
      for (const index in guestFlow) {
        if (index && guestFlow[index]) {
          temp.elements.push(new PanelElement({
            // @ts-ignore
            name: guestFlow[index].name,
            linkAction: 'GuestFlow',
            // @ts-ignore
            linkModuleId: guestFlow[index].id,
            linkLayout: 'element',
            // @ts-ignore
            image: guestFlow[index].image ? guestFlow[index].image.imageUrl : null,
            // @ts-ignore
            imageId: guestFlow[index].image ? guestFlow[index].image.id : null,
            active: true,
          }));
        }
      }
      this.selectedPanel = temp;
      this.selectedPanelIndex = this.panels.length;

      this.panels.push(temp);
      // @ts-ignore
      this.$refs.editPanel.$children[0].open();
    }
  }

  // add single item with panel
  singleItemRespone(result: any, module: IModule) {
    console.log('result', result);
    if (this.checkFloatingSection === false) {
      console.log('panel >>', this.floatingSection);
      if (this.floatingSection) {
        let element = new PanelElement();
        element = result[0];
        element.active = true;
        this.floatingSection.elements.push(element);
      } else {
        const panel = new DashboardPanel();
        panel.elementType = 'floating';
        panel.name = 'Floating Section';
        panel.active = true;
        panel.panelType = 'bottom-floating';
        panel.position = 'top';
        const panelElement = createItemPanelElement(module, result[0]);
        panel.elements.push(panelElement);
        this.floatingSection = panel;
      }

      // this.floatingSection.push(panel);
      // let element = new PanelElement();
      // element = result[0];
      // element.active = true;
      // this.floatingSection.elements.push(element);
    } else {
      this.panels[this.selectedPanelIndex].elements.push(result[0]);
    }
  }

  // show pop to add image with element of panel
  showImagesForElement(panelId: any, elementId: any) {
    this.selectedPanelIndex = panelId;
    this.selectedElementIndex = elementId;
    // @ts-ignore
    this.$refs.elementImage.$children[0].open();
  }

  // attach image with element
  attachImageToElement(img: any) {
    const element = this.panels[this.selectedPanelIndex].elements[this.selectedElementIndex];
    element.image = img.result.imageUrl;
    element.imageId = img.result.id;
    // @ts-ignore
    this.$refs.elementImage.$children[0].close();
  }

  showImagesForFoating(elementId: any) {
    this.selectedElementIndex = elementId;
    // @ts-ignore
    this.$refs.floatingImage.$children[0].open();
  }

  attachImageToFloating(img: any) {
    const element = this.floatingSection.elements[this.selectedElementIndex];
    element.image = img.result.imageUrl;
    element.imageId = img.result.id;
    // @ts-ignore
    this.$refs.floatingImage.$children[0].close();
  }

  // banner slider handlers start
  removeSlider() {
    this.slider = null;
    clearInterval(this.sliderInterval);
  }

  removeSlide(slide: any) {
    this.nextSlide();
    this.slider.elements = this.slider.elements.filter((item: any) => {
      return item !== slide;
    });
    const temp: PanelElement[] = [];
    for (const index in this.slider.elements) {
      if (index) {
        temp.push(this.slider.elements[index]);
      }
    }
    this.slider.elements = temp;
  }

  showAddSlide(index: any) {
    // @ts-ignore
    this.$refs.slide.$children[0].open();
  }

  attachSlide(imgObj: any) {
    const ele = new PanelElement();
    ele.image = imgObj.result.imageUrl;
    ele.imageId = imgObj.result.id;
    ele.active = true;
    ele.elementImage.type = imgObj.result.type;
    this.slider.elements.push(ele);
    // @ts-ignore
    this.slider.elements[this.slider.elements.length - 1].active = true;
    this.$delete(ele, 'foo');
    // @ts-ignore
    this.$refs.slide.$children[0].close();
    clearInterval(this.sliderInterval);
    if (this.slider && this.slider.elements.length > 0) {
      this.sliderInterval = setInterval(() => {
        // this.nextSlide();
      }, 5000);
    }
  }

  nextSlide() {
    const activeElement = this.slider.linkElementId;
    if (activeElement === this.slider.elements.length - 1) {
      this.slider.elements[activeElement].active = false;
      if (this.slider.elements[0]) {
        this.slider.elements[0].active = true;
      }
      this.slider.linkElementId = 0;
    } else {
      this.slider.elements[activeElement].active = false;
      this.slider.elements[activeElement + 1].active = true;
      this.slider.linkElementId = activeElement + 1;
    }
  }

  preSlide() {
    let activeElement = this.slider.linkElementId;
    if (activeElement !== 0) {
      this.slider.elements[activeElement].active = false;
      this.slider.elements[activeElement - 1].active = true;
      this.slider.linkElementId = activeElement - 1;
    } else {
      this.slider.elements[activeElement].active = false;
      activeElement = this.slider.elements.length - 1;
      this.slider.elements[activeElement].active = true;
      this.slider.linkElementId = activeElement;
    }
  }

  // banner slider handlers end here
  removePanel(panel: any) {
    this.panels = this.panels.filter((item: any) => {
      return panel !== item;
    });
  }

  updatePanel(result: any) {
    this.$set(this.panels, result.panelIndex, result.panel);
    console.log('selectedId at main', this.selectedPanelIndex);
  }

  removeElement(element: any, index: any) {
    console.log('remove element cliked');
    this.panels[index].elements = this.panels[index].elements.filter((item: any) => {
      return item !== element;
    });
  }

  /**
   * Left Side module Setting Area start
   */
  radiusControll() {
    const list = document.getElementsByClassName('b-radius');
    for (const i in list) {
      // @ts-ignore
      if (i && list[i] && list[i].style) {
        // @ts-ignore
        list[i].style.borderRadius = this.dashboardSetting.radius + '%';
      }
    }
  }

  /**
   * Lift Side module Setting Area End
   */

  getHeaders() {
    return stores.File.getHeader();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + '/' + stores.File.uploadUrl();
  }

  /**
   * Galley Pop up configuration end here
   */

  /**
   * Logo setting start here
   */
  // header handlers
  cancelLogo() {
    this.dashboardSetting.logo = null;
    this.dashboardSetting.imageId = null;
    this.$forceUpdate();
  }

  attachLogo(imgObj: any) {
    this.dashboardSetting.logo = imgObj.result.imageUrl;
    this.dashboardSetting.imageId = imgObj.result.id;
    this.$forceUpdate();
  }

  selectLogo() {
    // @ts-ignore
    this.$refs.logo.$children[0].open();
  }

  removeFloating(element: any) {
    console.log('remove element cliked');
    this.floatingSection.elements = this.floatingSection.elements.filter((item: any) => {
      return item !== element;
    });
    console.log('remove element cliked', this.floatingSection.elements);

  }

  /**
   * Logo setting end here
   */

  dashboardHandler(dashboard: any) {
    this.dashboardSetting = dashboard;
  }

}
