
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';

import ItemsMultiIndexGeneric from '@/components/ListNevronComponents/Items/IndexGenericMultiTable.vue';


@Component({
  components: {
    ItemsMultiIndexGeneric,

  },
})

export default class PropertyAssets extends Vue {
  // Component logic goes here
  stores = stores;

};
