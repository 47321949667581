var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-tabs',{attrs:{"pills":"","justified":"","content-class":"p-3 text-muted"}},[_c('b-tab',{staticClass:"border-0",attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-home"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v(_vm._s(_vm.translate("manage-property")))])]},proxy:true}])},[_c('ItemsMultiIndexGeneric',{attrs:{"instance":{
                            assets: _vm.stores.Assets,
                            assetsCategory: _vm.stores.AssetCategory,
                            routeName: 'manageProperty'
                          }}})],1),_c('b-tab',{staticClass:"border-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-home"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v(_vm._s(_vm.translate("location")))])]},proxy:true}])},[_c('ItemsMultiIndexGeneric',{attrs:{"instance":{
                              zones: _vm.stores.Locations,
                              zoneCategory: _vm.stores.LocationCategory,
                              routeName: 'location',
                          }}})],1),_c('b-tab',{staticClass:"border-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"d-inline-block d-sm-none"},[_c('i',{staticClass:"fas fa-home"})]),_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v(_vm._s(_vm.translate("devices")))])]},proxy:true}])},[_c('ItemsMultiIndexGeneric',{attrs:{"instance":{
                            device: _vm.stores.Devices,
                            deviceCategory: _vm.stores.DeviceType,
                            routeName: 'device',
                          }}})],1)],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }