import stores from '@/stores';

/**
 * Checks if this field is disabled for this module
 *
 * @param field
 * @param module
 */
export function isSettingDisabled(field: any, module: any) {
    module = convertToCamelCase(module);
    field = convertToCamelCase(field);
    if (!stores.admin.modulesSettings.hasOwnProperty(module)) {
      return false;
    }

    return !stores.admin.modulesSettings[module][field];
}

export function convertToCamelCase(string: any): string {
  // tslint:disable-next-line:only-arrow-functions
  return string.toLowerCase().replace(/[-_](\w)/g, function(match: any, letter: string) {
    return letter.toUpperCase();
  });
}
