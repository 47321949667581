
import {Component, Vue} from "vue-property-decorator";
import globalConfig from "../../stores/GlobalConfig";
import stores from "@/stores";
import {FormWizard, TabContent} from "vue-form-wizard";
import {showToaster} from "@/components/DashboardComposer/helpers";
import {Setting} from "@/stores/Setting";
import DashboardSettings from "@/components/DashboardComposer/DashboardSettings.vue";

@Component({
  components: {FormWizard, TabContent}
})
export default class ProjectManagement extends Vue {
  config = globalConfig.config;

  project = {
    id: '',
    name: '',
    settings: [] as ISetting[]
  };

  error = false;
  errorMessage = '';
  loading = false;
  isEditMode = false;

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  allSettings: ISetting[] = [];
  featuresList: IFeature[] = [];
  catalogueOptions: any = [];
  showPayment: boolean = false;
  onlinePayment: ISetting = {
    key: 'feature.SETTINGS.online-payments',
    value: false,
  }

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  handleOptionChange(key: string) {
    const option = this.catalogueOptions.find((option: any) => option.key === key);
    if (option) {
      option.value = !option.value;
    }

    if(this.catalogueOptions.some((option: any)=> option.value === true)) {
      this.showPayment = true;
    } else {
      this.showPayment = false;
    }
  }
  isOptionSelected(key: string) {
    const option = this.catalogueOptions.find((option: any) => option.key === key);
    return option ? option.value === true : false;
  }

  async mounted() {
    await stores.master.getFeatures();
    this.featuresList = stores.master.features || [];
    if (this.$route.params.id) {
      this.isEditMode = true;
      await this.fetchData(this.$route.params.id);
    }
    this.prepareSettings();

    // loading catalogue limitation options
    this.catalogueOptions = [
      {key: 'module-type.CATALOGUE.reservations', name:'Reservartions', value: false},
      {key: 'module-type.CATALOGUE.orders', name:'Orders', value: false},
    ];

  }

  fetchData(id: string) {
    return stores.master.getSpecificProject(id)
      .then((response: any) => {
        this.project = response.data;
        this.project.id = id;
        this.project.settings = JSON.parse(response.data.settings);
      })
      .catch(() => {
      });
  }

  prepareSettings() {
    for (const feature of this.featuresList) {
      this.allSettings.push({
        key: feature.permission,
        value: undefined,
      });
      if (feature.settingKey) {
        this.allSettings.push({
          key: feature.settingKey,
          value: undefined,
        });
      }
    }
    for (const setting of this.project.settings) {
      this.updateSettingValue(setting.key, setting.value);
    }
  }

  allChecked() {
    for (const setting of this.allSettings) {
      if(setting.value === false || setting.value === undefined) {
        if(!setting.key.includes('.limit')) {
          return false;
        }
      }
    }

    return true;
  }

  allCheckedInGroup(group: any) {
    for (const feature of group) {
      if(this.getSettingValue(feature.permission) === false || this.getSettingValue(feature.permission) === undefined) {
        return false;
      }
    }

    return true;
  }

  setValueToAllFeatures(value: boolean) {
    for (let index = 0; index < this.allSettings.length; index++) {
      const setting = this.allSettings[index];
      if (setting.key.includes('.limit')) {
        continue;
      }
      setting.value = value;
    }
  }

  setValueToAllFeaturesInGroup(group: any, value: boolean) {
    for (const feature of group) {
      this.updateSettingValue(feature.permission, value);
    }
  }

  getSettingValue(settingKey: string): any {
    const setting = this.allSettings.find((setting: ISetting) => setting. key == settingKey);
    return setting?.value;
  }

  updateSettingValue(settingKey: string, value: any) {
    const index = this.allSettings.findIndex((setting: ISetting) => setting.key == settingKey);
    // Update value of the setting
    this.allSettings[index].value = value;
  }

  validate() {
    if (this.project.name.length === 0) {
      this.errorMessage = 'Required field';
    } else if (this.project && !(/^[a-zA-Z0-9 ]+$/.test(this.project.name))) {
      this.errorMessage = 'Name can only contain letters, numbers and spaces.';
    } else {
      this.errorMessage = '';
    }

    return this.errorMessage.length === 0;
  }

  onStepValidate() {
    return new Promise((resolve, reject) => {
      if (!this.validate()) {
        reject(this.errorMessage);
      }
      resolve(true);
    });
  }

  onSubmit() {
    if (this.validate()) {
      this.loading = true;

      // preparing catalogue limitation options
      const aditionalOptions = this.catalogueOptions.filter((setting: any)=> setting.value)
      .map(({ key, value }: { key: string; value: any }) => ({ key, value }));
      if(this.showPayment && this.onlinePayment.value) {
        aditionalOptions.push(this.onlinePayment);
      }

      this.project.settings = [...this.allSettings.filter(setting => setting.value), ...aditionalOptions];
      if(!this.isEditMode) {
        stores.master.createProject(this.project)
          .then((response) => {
            if (!response.hasOwnProperty('tenantId')) {
              this.error = true;
              this.errorMessage = response.message
              this.loading = false;
            } else {
              window.location.href = `${window.location.origin}/admin/projects/finish/created`;
            }
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.error = true;
              this.errorMessage = error.response.data.message;
              this.loading = false;
            }
          });
      } else {
        stores.master.updateProject(this.project.id, this.project)
          .then((response) => {
            if (response.error !== null) {
              this.error = true;
              this.errorMessage = response.message;
              this.loading = false;
            } else {
              window.location.href = `${window.location.origin}/admin/projects/finish/edited`;
            }
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.error = true;
              this.errorMessage = error.response.data.message;
              this.loading = false;
            }
          });
      }

    }
  }
}
