
import {Component, Vue, Watch} from 'vue-property-decorator';
import AdminChangePassword from '@/modules/Admins/ChangePassword.vue';
import {Admin} from '../../stores/Admins';
import NevronHeader from '@/components/NevronHeader.vue';
import stores from '../../stores';
import Skeleton from '@/modules/Skeleton.vue';
import NevronInput from '@/components/NevronInput.vue';
import {showToaster} from "@/components/DashboardComposer/helpers";
import Multiselect from "vue-multiselect";
import {hasPermissionForRoleByType} from "@/helpers/permissions";

interface Role {
  createdAt: string;
  guardName: string;
  id: number;
  name: string;
  updatedAt: string;
}

@Component({
  components: {
    Multiselect,
    AdminChangePassword,
    NevronHeader,
    Skeleton,
    NevronInput,
  },
})

export default class AdminDetails extends Vue {
  currentAdmin = stores.admin;
  admin: Admin = new Admin();
  roles: [] = [];

  /*
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.fetchData(this.$route.params.id);
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(this.$route.params.id);

    stores.roles.getAllRoles().then((response) => {
      this.roles = response;
    });
  }

  getScopedRoles() {
    const scopedRoles: Role[] = this.roles
      .filter((role: Role) => hasPermissionForRoleByType(role.name, 'CREATE'))
      .sort((a: Role, b: Role) => {
        if (a.name === 'User' || a.name === 'Admin') {
          return 1; // Move 'User' and 'Admin' roles to the end
        } else if (b.name === 'User' || b.name === 'Admin') {
          return -1; // Keep 'User' and 'Admin' roles in their current order
        }
        return 0; // Maintain the order of other roles
      });

    return scopedRoles;
  }

  /*
   *
   */
  fetchData(id: string) {
    Admin.getById(id)
      .then((admin) => {
        this.admin = admin;
        console.log('This admin', this.admin);
      })
      .catch((reason) => {
        console.log('Could not fetch admin', reason);
        if (!this.admin.id) {
          this.$router.push({name: 'access.roles'});
        }
      });
  }

  /*
   *
   */
  onSubmit() {
    const data = {
      id: this.admin.id,
      name: this.admin.name,
      username: this.admin.username,
      email: this.admin.email,
      roles: this.admin.roles
    };

    return stores.admins.update(data)
      .then(() => {
        showToaster('success', Vue.prototype.translate(this.admin.name), 'successfully updated');
        console.log('success');
      }).catch((e: any) => {
        showToaster('danger', Vue.prototype.translate(this.admin.name), 'failed to update');
        console.log(e);
      });
  }

  /*
   *
   */
  onSubmitStatus() {
    console.log(this.admin.status);
    return stores.admins.update({id: this.admin.id, status: this.admin.status})
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /*
   *
   */
  editPass(user: IAdmin) {
    if (user.id) {
      return stores.admins.changePassword(user.id, user)
        .then(() => {
          user.password = '';
          user.passwordConfirmation = '';
          // @ts-ignore
          this.$refs.pass.$children[0].close();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  /*
   *
   */
  removeAdmin() {
    if (this.admin.id) {
      return stores.admins.delete(this.admin.id)
        .then(() => {
          console.log('Success');
          this.$router.push('access.roles');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}
